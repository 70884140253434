/**
 * Define the layout for the main content of the page, when the sidebar is enabled.
 * TODO#strips: the strips check can be removed once all templates are updated to support strips
 */
.jw-is-strips {
    &.jw-is-sidebar {
        .main-content {
            display: flex;
            flex-direction: column;
        }

        .block-content,
        .block-sidebar {
            max-width: calc(
                var(--simple-brick-max-width) +
                    (var(--strip-content-padding-inline) * 2)
            );
        }

        .block-sidebar {
            // flex is used to make the element grow to full height, which is needed for borders/backgrounds
            display: flex;
            margin-top: var(--strip-content-padding-block--primary);
            margin-bottom: var(--strip-content-padding-block--primary);
        }

        .jw-section-sidebar {
            box-sizing: border-box;
            width: 100%;
            padding: var(--strip-content-padding-block--primary)
                var(--strip-content-padding-inline);
        }
    }
}

/**
 * Generic class for container query-like behavior.
 * The contained mixins use native container queries if supported by the browser, but fall
 * back to using our own implementation -- based on user agent in the backend and JS
 * computation in the frontend -- if not.
 *
 * Note: our fallback only works if using one of our predefined breakpoints. See
         RenderOptions::getResponsiveContainerClasses().
 */
.jw-responsive {
    // Class to opt in to native container queries.
    // We don't enable them for all .jw-responsive elements by default, because that has
    // some complications (native containers have layout containment, which means they can't
    // participate in margin collapsing + have their own stacking context)
    &--native {
        container-type: inline-size;
    }

    // Class to indicate the root container of page content.
    // It's applied to each template's root content element, and is used to control 'global'
    // responsive behavior -- like collapsing columns on mobile -- without using media
    // queries. Note that .jw-responsive should also be used, or else the JS-based fallback
    // behavior won't work.
    //
    &-root {
        container-name: root;
    }

    // Set to `false` to disable native container queries. Use this to test fallback
    // behavior.
    @enable-container-queries: true;

    /**
     * Apply @container-rules if container queries are supported and apply @fallback-rules
     * otherwise (or if @enable-container-queries is false).
     * Note: only works for content .jw-responsive--native elements.
     */
    .polyfill(@container-rules, @fallback-rules)
        when
        (@enable-container-queries = true) {
        @supports (container-type: inline-size) {
            @container-rules();
        }
        @supports not (container-type: inline-size) {
            @fallback-rules();
        }
    }
    .polyfill(@container-rules, @fallback-rules)
        when
        (@enable-container-queries = false) {
        @fallback-rules();
    }

    /**
     * Define styles for when the nearest container's width is <= @breakpoint.
     * Fallback will only work if using one of our predefined breakpoints and if the@ name
     * -- if specified -- is also in the class name on the container element
     * (`jw-responsive-@{name}`)
     */
    .lte(@breakpoint, @rules) {
        .polyfill(
            {
                @container (width <= @breakpoint) {
                    @rules();
                }
            },
            {
                @breakpointpx: unit(@breakpoint);
                .jw-responsive--native.lt@{breakpointpx} & {
                    @rules();
                }
            }
        );
    }
    .lte(@breakpoint, @rules, @name) {
        .polyfill(
            {
                @container @name (width <= @breakpoint) {
                    @rules();
                }
            },
            {
                @breakpointpx: unit(@breakpoint);
                .jw-responsive-@{name}.lt@{breakpointpx} & {
                    @rules();
                }
            }
        );
    }

    /**
     * Define styles for when the nearest container's width is greater than @breakpoint.
     * Fallback will only work if using one of our predefined breakpoints and if the @name
     * -- if specified -- is also a class name on the container element.
     */
    .gt(@breakpoint, @rules) {
        .polyfill(
            {
                @container (width > @breakpoint) {
                    @rules();
                }
            },
            {
                @breakpointpx: unit(@breakpoint);
                .jw-responsive--native:not(.lt@{breakpointpx}) & {
                    @rules();
                }
            }
        );
    }
    .gt(@breakpoint, @rules, @name) {
        .polyfill(
            {
                @container @name (width > @breakpoint) {
                    @rules();
                }
            },
            {
                @breakpointpx: unit(@breakpoint);
                .jw-responsive-@{name}:not(.lt@{breakpointpx}) & {
                    @rules();
                }
            }
        );
    }
}

@jw-collapse-breakpoint: 640px;

// Apply styles when content is displayed in full.
// Styles are applied in both a media query and a (polyfilled) container query. Technically
// we could use just the container query, but native CSS containers have some limitations due
// to their layout containment, as it can break fixed positioning and z-index stacking.
//
// Issues we had when we tried to make .jw-body a native responsive container:
//   - menu no longer fully visible when longer than the page (#14446)
//   - mobile bar rendered at bottom of the page instead of the viewport (#14446)
//
// ... then we moved the container CSS further down the tree, to wrap just the page content
// (main+sidebar+footer). Then we still had issues:
//
//  - sticky footers broken (#14458, although fixed by making the mixin more flexible)
//  - toolbar positioning broken on mobile + when scrolled out of view on desktop (#14489)
//  - columns in table no longer resizable (#14480, maybe fixed with a newer version of
//    datatables)
//  - random scroll positioning after adding webshop product to cart (#14484)
//  - custom HTML elements no longer support fixed positioning (#14455, but wontfixed due to
//    us not providing guarantees for custom HTML content)
//
// We could theoretically fix some of the issues by making the <html> element a responsive
// container, but that would require making the <body> the root scroll container, and that
// might have some problems on its own.
// @see https://www.oddbird.net/2023/07/05/contain-root/
//
// Note that we still also apply container query CSS, because that allows us to render
// website content previews in Shadow DOM (without using an iframe), which is useful for our
// strip template library.
.jw-layout-full(@rules) {
    @media (min-width: (@jw-collapse-breakpoint + 1)) {
        .jw-body & {
            @rules();
        }
    }

    .jw-responsive.gt(@jw-collapse-breakpoint, @rules, root);
}

// Apply styles when content is collapsed to a single column
// @see .jw-layout-full for a thorough explanation for how this is set up.
.jw-layout-collapsed(@rules) {
    @media (max-width: @jw-collapse-breakpoint) {
        .jw-body & {
            @rules();
        }
    }

    .jw-responsive.lte(@jw-collapse-breakpoint, @rules, root);
}
