@import (reference) './columns';

@vertical-separator-width: 2px;
@placeholder-offset: 8px; // see PLACEHOLDER_OFFSET in highlightAction.ts

// Show separator between columns of horizontal split
.jw-tree-horizontal__insert-highlight {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -(@vertical-separator-width / 2);
    z-index: @zindex-jw-action-placeholders;

    display: block;
    width: @vertical-separator-width;

    border-radius: @border-radius-base;
    background-color: @brand-primary;
    box-shadow: 0 0 0 2px fade(@brand-primary, 20%);

    .jw-node-is-last-child > & {
        right: (@columns-spacing / 2) - 8px - (@vertical-separator-width / 2);
    }
}

.jw-tree-horizontal__insert-highlight--is-left {
    right: auto;
    left: -(@vertical-separator-width / 2);

    .jw-node-is-first-child > & {
        left: (@columns-spacing / 2) - 8px - (@vertical-separator-width / 2);
    }
}

// Make container fill complete placeholder
//
// Note: cannot apply this by default because applying Flexbox will
//       break collapsing margins behaviour that is used to add
//       margin between text.
.jw-tree-container.jw-tree-container--empty {
    display: flex;
    // This fixes an issue where the breadcrumbs and the empty content are rendered next to each other
    // instead of under each other in the editor, but I'm not sure about the side-effects.
    flex-direction: column;
}

.jw-tree-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: @zindex-jw-element-overlay;

    user-select: none;
}

// Empty column placeholder
.jw-tree-empty-placeholder {
    position: relative;
    text-align: center;
    display: none;
    min-height: 50px;
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;

    color: fade(black, 60%);
    border: 1px dashed fade(black, 30%);
    border-radius: @border-radius-base;

    transition:
        border 150ms ease,
        background 150ms ease,
        box-shadow 150ms ease;

    &--pinned {
        border: 1px dashed fade(@brand-warning, 70%) !important;
    }

    &__wrap {
        &--ai {
            @media (min-width: @screen-sm-min) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
            }

            .jw-tree-empty-placeholder__drag {
                border-bottom: 1px solid fade(black, 30%);
                @media (min-width: @screen-sm-min) {
                    border-bottom: none;
                    border-right: 1px solid fade(black, 30%);
                }
            }
            .jw-tree-empty-placeholder__generate {
                margin-top: @spacing-base * 6;
                @media (min-width: @screen-sm-min) {
                    margin-top: 0;
                }
            }
        }
    }
}

.jw-tree-empty-placeholder.jw-tree-empty-placeholder-is-deleting {
    border: 1px solid @brand-danger !important;
    box-shadow: 0 0 0 2px fade(@brand-danger, 20%);
}

.jw-tree-container--empty .jw-tree-empty-placeholder {
    display: block;
}

.is-picking .jw-tree-container--empty > .jw-tree-empty-placeholder {
    display: block !important;

    // Don't show "delete column" button while dragging an element
    .jw-context-remove-wrap {
        display: none !important;
    }
}

.jw-tree-empty-placeholder.jw-tree-empty-placeholder-visible {
    display: block;
}

.jw-tree-empty-placeholder.jw-tree-empty-placeholder-selected,
.jw-tree-empty-placeholder.jw-highlighted-for-tour {
    border: 1px solid @brand-primary !important;
    background-color: fade(@brand-primary, 10%) !important;
    box-shadow: 0 0 0 2px fade(@brand-primary, 20%);
}

.jw-tree-empty-placeholder__image {
    height: 120px;
    background: url('~assets/img/content-empty.png') no-repeat center top;
}

// Do not show empty placeholder in footer by default
.jw-section-footer
    > .jw-tree-container.jw-tree-container--empty
    > .jw-tree-empty-placeholder {
    display: none;
}

// Visualize effect of applying an action
.jw-placeholder {
    display: none;
    position: absolute;
    z-index: @zindex-jw-action-placeholders;
    box-sizing: border-box;

    border-radius: @border-radius-base;
    border: 1px solid @brand-primary !important;
    background-color: fade(@brand-primary, 10%) !important;
    box-shadow: 0 0 0 2px fade(@brand-primary, 20%);

    &--line {
        background-color: @brand-primary !important;
        border-radius: 0;
    }
}
